@font-face {
    font-family: 'Eagle';
    src: local('Eagle'), url('static/fonts/Eagle-Bold.ttf') format('opentype');
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'), url('static/fonts/Inter-Medium.ttf') format('truetype');
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none !important;
}

a:active, a:focus {
    text-decoration: none !important;
    color:inherit !important;
}

.menu-element {
    font-weight: bold;
    color: #676767;
    transition: all 0.3s;
}

.menu-element:hover, .menu-element.active {
    color: #989898;
}

.MuiButton-contained {
    color: white !important;
}

.MuiSlider-valueLabelOpen {
    background-color: #bee7dc !important;
    color:black !important;
}

.dropzone-ui-extra {
    border: solid thin gray !important;
}

.dz-ui-footer {
    border-top: solid thin lightgray !important;
}
.rs-picker-toggle-textbox {
    background-color: #003C2D !important;
}
.rs-picker-toggle-read-only {
    opacity: 1 !important;
}
.rs-picker-default .rs-picker-toggle {
    border: 1px solid gray !important;
}
.rs-picker-toggle-textbox {
    color: #E7E7E7 !important;
}

@media (max-width: 1024px) {
    h1 {
        font-size: 3rem !important;
    }
    h2 {
        font-size: 2.5rem !important;
    }
    h3 {
        font-size: 2rem !important;
    }
    h4 {
        font-size: 1.5rem !important;
    }
}